// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// swagger-ui
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'

// semantic-ui
import { Container, Header, Icon } from 'semantic-ui-react'

// services
import { getApi } from 'services/api-catalog'
import { isAuthenticated } from 'services/self'

// components
import ApisMenu from 'components/ApisMenu'
import SwaggerLayoutPlugin from 'components/SwaggerUiLayout'
import AuthorizationAutomator from 'components/AuthorizationAutomator'

// state
import { store } from 'services/state.js'
import { observer } from 'mobx-react'
import { init } from 'services/self.js'
import { subscribe  } from 'services/api-catalog'


export default observer(class ApisPage extends React.Component {

  componentDidMount() {
    this.authAutomatorRef = React.createRef();
    this.updateApi() ;
    init();

    // Temporary fix (until the swagger configuration is defined) -
    // Function which observes the DOM for any changes when the component loads intitially.
    // In this scenario the h3 header changes from null to the swagger defined message "No operations defined in spec!".
    // This header tag is checked for and changed from the existing message the new message
    const observer = new MutationObserver((mutationList, observer) => {
      const swaggerUi = document.querySelector('section.block h3');
      if(swaggerUi && swaggerUi.textContent.trim() === "No operations defined in spec!"){
        document.querySelector('section.block h3').textContent = "Coming Soon!";
      }
    });

    
    observer.observe(document.body, {childList: true, subtree: true});
    if(document.querySelector('section.block h3') && document.querySelector('section.block h3').textContent === "Coming Soon!"){
      observer.disconnect();
      
    }
  }

  componentDidUpdate() {
    this.updateApi().then(() => {
      if (this.authAutomatorRef.current) {
        // Reauthorize when component got updated.
        this.authAutomatorRef.current.automateApiAuthorization();
      }
    });
  }

  updateApi = async () => {
    const api = await getApi(this.props.match.params.apiId || 'ANY', true);
        if (api) {
          let swaggerUiConfig = {
            dom_id: '#swagger-ui-container',
            defaultModelsExpandDepth: 1,
            defaultModelExpandDepth: 1,
            docExpansion: "list",
            plugins: [SwaggerLayoutPlugin],
            validatorUrl: "none",
            supportedSubmitMethods: [],
            spec: api.swagger,
            onComplete: () => {
              if (store.apiKey)
                uiHandler.preauthorizeApiKey("api_key", store.apiKey)
            }
          }
          if (isAuthenticated()) {
            delete swaggerUiConfig.supportedSubmitMethods
          } else {
            delete swaggerUiConfig.spec
          }

          let uiHandler = SwaggerUI(swaggerUiConfig)
          if ( isAuthenticated() &&  !api.subscribed ) {
            //subscribing
            subscribe(api.usagePlan.id)
          }
        }
  }

  render() {
    let errorHeader
    let errorBody

    if (!store.apiList.apiGateway.length && !store.apiList.generic.length) {
      errorHeader = `No APIs Published`
      errorBody = `Your administrator hasn't added any APIs to your account. Please contact them to publish an API.`
    } else if (!store.api) {
      errorHeader = `No Such API`
      errorBody = `The selected API doesn't exist.`
    }

    if (errorHeader && errorBody) {
        console.log(errorHeader && errorBody)
    }

    return (
      <div style={{ display: "flex", flex: "1 1 auto", overflow: "hidden" }}>
       {isAuthenticated() && (
        <React.Fragment>
        <AuthorizationAutomator ref={this.authAutomatorRef} />
        <ApisMenu path={this.props.match} style={{ flex: "0 0 auto" }}  />
        </React.Fragment>
       )}
        <div className="swagger-section" style={{ flex: "1 1 auto", overflow: 'auto' }}>
          <div className="swagger-ui-wrap" id="swagger-ui-container" style={{ padding: "0 20px" }}>
            {errorHeader && errorBody && (
              <React.Fragment>
                <Header as='h2' icon textAlign="center" style={{ padding: "40px 0px" }}>
                  <Icon name='warning sign' circular />
                  <Header.Content>{errorHeader}</Header.Content>
                </Header>
                <Container text textAlign='justified'>
                  <p>{errorBody}</p>
                </Container>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    )
  }
})
