// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Link } from 'react-router-dom'
import { Menu, Image } from 'semantic-ui-react'

import { isAuthenticated, logout } from 'services/self'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// components
import SignIn from './SignIn'
import Register from './Register'

import ApiToken from './ApiToken'
import AlertBox from './AlertBox';


export const NavBar = observer(() => {
	window.alertBoxRef = React.createRef()
	
	return (
    
		<Menu inverted borderless attached style={{ flex: "0 0 auto" }}>
		  <AlertBox ref = {window.alertBoxRef} />

		  <Menu.Item as={Link} to="/">
			<Image size='mini' src="/custom-content/helia.png" style={{ paddingRight: "10px" }} />
			{fragments.Home.title}
		  </Menu.Item>
		  
		  {isAuthenticated() &&
			<React.Fragment>
			  <Menu.Item as={Link} to="/getting-started">{fragments.GettingStarted.title}</Menu.Item>
			  <Menu.Item as={Link} to="/apis">{fragments.APIs.title}</Menu.Item>
			  <Menu.Item as={Link} to="/b2b-gateway">B2B Gateway</Menu.Item>
			  <Menu.Item as={Link} to="/dashboard">My Dashboard</Menu.Item>
			  <Menu.Item as={Link} to="/termsandconditions">Terms and Conditions</Menu.Item>
			</React.Fragment>
		  }
		  <Menu.Menu position="right">
			{isAuthenticated() ? (
			  <React.Fragment>
				<ApiToken trigger={<Menu.Item as="a">API Authentication</Menu.Item>} />
				<Menu.Item as="a" onClick={logout}>Sign Out</Menu.Item>
			  </React.Fragment>
			) : (
				<React.Fragment>
				  <SignIn trigger={<Menu.Item as="a">Sign In</Menu.Item>} />
				</React.Fragment>
			)}
			</Menu.Menu>
		</Menu>
	)
})

export default NavBar
