// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import React from 'react'
import ReactDOM from 'react-dom'
import { Grid, Popup, Button, Icon, Form, Message, Modal } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { login , getApiToken, forgotPassword, confirmPassword, 
		validateEmail, validatePassword, getPasswordRequirement } from 'services/self'
import { confirmMarketplaceSubscription } from 'services/api-catalog'


import { store } from 'services/state'

export default class ResetPassword extends React.Component {

	state = {
		isSubmitting: false,
		isOpen: false,
		handlingForgetPassword: false,
		isEmailValid: true,
		isPasswordConsistent: true,
		isPasswordStrongEnough: true
	}

	constructor(props) {
		super(props);
	}

	open = () => this.setState({ isSubmitting: false, isOpen: true, email: "", isEmailValid: true, 
									code: "", newpass: "", conf_newpass: "",
									isPasswordConsistent: true, isPasswordStrongEnough: true })
	close = () => this.setState({ isOpen: false })

	handleDoubleClick = (event) => { event.target.select(); }

	handleChange = (event, { name, value }) => {
		this.setState({ [name]: value })

		if (name === 'newpass' || name === 'conf_newpass') {
			this.setState({ isPasswordConsistent: (value === this.state.newpass || value === this.state.conf_newpass),
							isPasswordStrongEnough: validatePassword(value) })
		} else if (name === 'email') {
			this.setState({isEmailValid: validateEmail(value)})
		}
	}

	isAllInputDataValid = () => {
		return this.state.isEmailValid && 
				this.state.isPasswordConsistent && 
				this.state.isPasswordStrongEnough &&
				this.state.newpass && this.state.newpass.trim() &&
				this.state.code && this.state.code.trim()
	}

    handleForgotPassword = (event) => {
		event.preventDefault()
		event.stopPropagation()

		this.setState({ handlingForgetPassword: true })

		forgotPassword(this.state.email.toLowerCase(), {
			onSuccess: (result) => {
				this.setState({ handlingForgetPassword: false })
				window.alertBoxRef.current.open("Info", 
							'The code has been sent to your nominated email address.\n\n' +
							'Please copy and paste it into the \'Enter code\' field.\n' +
							'Please check your spam folder if you do not receive the email.', true)
			},
			onFailure: (err) => {
				this.setState({ handlingForgetPassword: false })
				var errorMessage = err.message
				if (err.code == 'UserNotFoundException' || err.code == 'UserNotConfirmedException' || err.code == 'ResourceNotFoundException' || err.code == 'NotAuthorizedException') {
					errorMessage = "Incorrect credentials."
				}
				window.alertBoxRef.current.open("Error", 'Failed to reset password.\n\nAWS: ' + errorMessage)
			}
		})
		
    }

    handleConfirmPassword = (event) => {
		event.preventDefault()
		event.stopPropagation()

		this.setState({isSubmitting: true})

		confirmPassword(this.state.email, this.state.newpass, this.state.code, {
			onSuccess: () => {
				this.setState({isSubmitting: false})
				window.alertBoxRef.current.open("Info", 
							'Your password has been successfully changed.\n\nPlease use the new password for login from now on!', true, 
							() => {
								this.close()
								if (this.props.userCredentialsUpdater) {
									this.props.userCredentialsUpdater(this.state.email, this.state.newpass)
								}
							})
			},
			onFailure: (err) => {
				var errorMessage = err.message
				if (err.code == 'UserNotFoundException' || err.code == 'UserNotConfirmedException' || err.code == 'ResourceNotFoundException' || err.code == 'NotAuthorizedException') {
					errorMessage = "Incorrect credentials."
				}

				this.setState({isSubmitting: false})
				window.alertBoxRef.current.open("Error", 
							'Failed to reset password.\n\nAWS: ' + errorMessage, false,
							() => {
								this.close()
							})
			}
		});
    }

    render() {

		const { isOpen } = this.state

		return (
			<Modal
				size='small'
				open={isOpen}
				onOpen={this.open}
				onClose={this.close}
				trigger={this.props.trigger}>

				<Modal.Header>Reset Password</Modal.Header>
				<Modal.Content>
				  <Form onSubmit={this.handleConfirmPassword} error={!this.isAllInputDataValid()} loading={this.state.isSubmitting}>
					<Grid>
						<Grid.Row>
							<Grid.Column width={13}>
								<Form.Input error={this.state.isEmailValid ? false : "Invalid email address"} fluid icon="at" name='email' 
											 autoComplete='false' iconPosition="left" placeholder="Email address" onChange={this.handleChange} />
							</Grid.Column>
							<Grid.Column width={3} style={{textAlign: 'right'}}>
								<Button primary loading={this.state.handlingForgetPassword} disabled={!validateEmail(this.state.email)} 
												onClick={this.handleForgotPassword}>Get code</Button>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row verticalAlign="middle">
							<Grid.Column width={16}>
								<Form.Input fluid icon="paste" name='code' iconPosition="left" autoComplete='false'
											placeholder="Enter code"
											onChange={this.handleChange}  />
							</Grid.Column>
						</Grid.Row>

						<Grid.Row>
							<Grid.Column width={16}>
								<Form.Input error={!(this.state.isPasswordConsistent && this.state.isPasswordStrongEnough)} autoComplete='false'
									fluid icon="lock" name='newpass' iconPosition="left" placeholder="New password" 
									type="password" onChange={this.handleChange}/>
								<Form.Input error={this.state.isPasswordConsistent && this.state.isPasswordStrongEnough ? false : 
													(!this.state.isPasswordConsistent ? "Inconsistent passwords" : 
													getPasswordRequirement() ) } 
									fluid icon="lock" name='conf_newpass' iconPosition="left" placeholder="Confirm password" 
									 autoComplete='false' type="password" onChange={this.handleChange} />
							</Grid.Column>
						</Grid.Row>

						<Grid.Row>
							<Grid.Column width={16} style={{textAlign: 'right'}}>
								<Modal.Actions style={{textAlign: 'right'}}>
								  <Button primary disabled={!this.isAllInputDataValid()} type='submit'>Reset</Button>
								  <Button type='button' onClick={this.close}>Cancel</Button>
								</Modal.Actions>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				  </Form>
				</Modal.Content>
		    </Modal>)
    }
}
