// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Button, Form, Message, Modal } from 'semantic-ui-react'
import { getApiToken } from 'services/self'



import { store } from 'services/state'

export default class ApiToken extends React.Component {
   
   constructor(props) {
      super(props)
      this.state = {
         isSubmitting: false,
         signedIn: false,
         errorMessage: '',
         isOpen: false,
         apiTokenLabel: 'API token',
         username: '',
         password: ''
      }
      this.timerId = null
   }

   open = () => {
       // This is to reset the values on the Authentication page, if earlier someone entered invalid values
       // and if the page is opened again, even tho it will show blank, its values are still remembered and the
       // Authentication button remains enabled. So reset the values if the user is not yet signed in.
       if (!this.state.signedIn){
           this.setState({ username: '', password: ''})
       }
       this.setState({ isSubmitting: false, errorMessage: '', isOpen: true })
   }
   close = () => this.setState({ isOpen: false })

   timerCallback = () => {
       var currentTime = new Date()
       if (store.tokenStartTime) {
           var left = 3600 - 5 - Math.round((currentTime.getTime() - store.tokenStartTime.getTime())/1000)
           this.setState( { apiTokenLabel: 'API token' + '  (will be auto-renewed in ' + left + ' seconds)' })
               // a small time window overlap to ensure seamless transition from old token to new token
           if (left <= 0) {
               store.tokenStartTime = null
               this.handleLoginInternal()
           }
       } else {
           this.setState( { apiTokenLabel: 'API token' })
       }
   }

   
   componentDidMount() {
       console.log("ApiToken componentDidMount")
       this.timerId = setInterval(this.timerCallback, 1000);
   } 
   
   componentWillUnmount() {
       console.log("ApiToken componentWillUnmount")
       clearInterval(this.timerId)
   }


   handleDoubleClick = (event) => { event.target.select(); }

   handleChange = (event, { name, value }) => this.setState({ [name]: value })

   handleLogin = (event) => {
    event.preventDefault()
    this.handleLoginInternal()
   }

   handleLoginInternal = () => {
    this.setState({isSubmitting: true})

    console.log("Getting API token for user '" + this.state.username + "' .." )
    getApiToken(this.state.username, this.state.password)
      .then()
	  .catch((e) => {
		var errorMessage = e.message
		if (e.code == 'UserNotFoundException' || e.code == 'UserNotConfirmedException' || e.code == 'ResourceNotFoundException' || e.code == 'NotAuthorizedException') {
					errorMessage = "Incorrect credentials."
				}
        this.setState({ signedIn: false, errorMessage: errorMessage, isSubmitting: false })
        return;
      })

    this.setState({signedIn: true, isSubmitting: false, errorMessage: ''})
   }
 
  render() {
    const { isOpen } = this.state
    
    return  (
      <Modal
        size='small'
        open={isOpen}
        onOpen={this.open}
        onClose={this.close}
        trigger={this.props.trigger}
      >
        <Modal.Header>API Authentication </Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.handleLogin} error={!!this.state.errorMessage} loading={this.state.isSubmitting}>
            <Form.Input label='API login username' name='username' value={store.username} onChange={this.handleChange} readOnly={this.state.signedIn}/>
            <Form.Input type='password' label='API login password' value={store.password} name='password' autoComplete='false' onChange={this.handleChange}  readOnly={this.state.signedIn}/>
            <Form.Input type='text' label={this.state.apiTokenLabel} id='apitokenInput' value={store.idToken ? store.idToken : 'To be generated'} onDoubleClick={this.handleDoubleClick}/>
            <Form.Input type='text' label='API key' id='apikey' value={store.apiKey} />
            
            <Message error content={this.state.errorMessage} />
            <Modal.Actions style={{textAlign: 'right'}}>
              <Button type='button' onClick={this.close}>Close</Button>
              <Button primary type='submit' disabled={!(this.state.username && this.state.password) }>Authenticate</Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>)
  }
}
