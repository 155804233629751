// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import ReactDOM from 'react-dom'
import { Grid, Popup, Button, Icon, Form, Message, Modal, Label } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { login , getApiToken, forgotPassword, confirmPassword, 
		validateEmail, validatePassword, getPasswordRequirement } from 'services/self'
import { confirmMarketplaceSubscription } from 'services/api-catalog'


import { store } from 'services/state'

export default class NewPassword extends React.Component {

	state = {
		isSubmitting: false,
		isOpen: false,
		isPasswordConsistent: true,
		isPasswordStrongEnough: true,
		handleLogin: null
	}

	constructor(props) {
		super(props);
	}

	open = (handleLogin) => this.setState({ isSubmitting: false, isOpen: true, newpass: "", conf_newpass: "",
									isPasswordConsistent: true, isPasswordStrongEnough: true,
									handleLogin: handleLogin })
	close = () => this.setState({ isOpen: false })

	handleChange = (event, { name, value }) => {
		this.setState({ [name]: value })

		if (name === 'newpass' || name === 'conf_newpass') {
			this.setState({ isPasswordConsistent: (value === this.state.newpass || value === this.state.conf_newpass),
							isPasswordStrongEnough: validatePassword(value) })
		}
	}

	isAllInputDataValid = () => {
		return this.state.isPasswordConsistent && 
				this.state.isPasswordStrongEnough &&
				this.state.newpass && this.state.newpass.trim()
	}


    handleSubmit = (event) => {
		event.preventDefault()
		event.stopPropagation()
		this.state.handleLogin(this.state.newpass)
		this.close()
    }

    render() {

		const { isOpen } = this.state

		return (
			<Modal
				size='small'
				open={isOpen}
				onOpen={this.open}
				onClose={this.close}
				trigger={this.props.trigger}>

				<Modal.Header>New password</Modal.Header>
				<Modal.Content>
				  <Form onSubmit={this.handleSubmit} error={!this.isAllInputDataValid()} loading={this.state.isSubmitting}>
					<Grid>
						<Grid.Row>
							<Grid.Column width={16}>
								<Label color='red' size='large'>This is your first time login. Please change your initial temporary password by specifying a new one</Label>
							</Grid.Column>
						</Grid.Row>

						<Grid.Row>
							<Grid.Column width={16}>
								<Form.Input error={!(this.state.isPasswordConsistent && this.state.isPasswordStrongEnough)} autoComplete='false'
									fluid icon="lock" name='newpass' iconPosition="left" placeholder="New password" 
									type="password" onChange={this.handleChange}/>
								<Form.Input error={this.state.isPasswordConsistent && this.state.isPasswordStrongEnough ? false : 
													(!this.state.isPasswordConsistent ? "Inconsistent passwords" : 
													getPasswordRequirement() ) } 
									fluid icon="lock" name='conf_newpass' iconPosition="left" placeholder="Confirm password" 
									 autoComplete='false' type="password" onChange={this.handleChange} />
							</Grid.Column>
						</Grid.Row>

						<Grid.Row>
							<Grid.Column width={16} style={{textAlign: 'right'}}>
								<Modal.Actions style={{textAlign: 'right'}}>
								  <Button primary disabled={!this.isAllInputDataValid()} type='submit'>OK</Button>
								  <Button type='button' onClick={this.close}>Cancel</Button>
								</Modal.Actions>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				  </Form>
				</Modal.Content>
		    </Modal>)
    }
}
