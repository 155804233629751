// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Button, Grid, Form, Message, Modal } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { login, validateEmail } from 'services/self'
import { confirmMarketplaceSubscription } from 'services/api-catalog'
import { store } from 'services/state'

import { isAuthenticated, logout } from 'services/self'
import ResetPassword from './ResetPassword'
import NewPassword from './NewPassword'
import VerifyEmail from './VerifyEmail'


export default class SignIn extends React.Component {
  newPasswordRef = React.createRef()
  verifyEmailRef = React.createRef()

  state = {
    isSubmitting: false,
    signedIn: false,
    errorMessage: '',
    isOpen: false,
    email:'',
    firstLogin: false,
	isEmailValid: true
  }
  open = () => {
    this.setState({ isSubmitting: false, errorMessage: '', isOpen: true, isEmailValid: true, firstLogin: false })
    this.updateUserCredentials("", "")
  }
  close = () => this.setState({ isOpen: false })

//   handleChange = (event, { name, value }) => {
//     if (name === "firstLogin") {
//       this.setState({ [name]: ! event.currentTarget.children[0].checked })
//     } else {
//       this.setState({ [name]: value })
// 	  if (name === 'email') {
//  		this.setState({isEmailValid: validateEmail(value)})
// 	  }
//     }
//   }

  handleChange = (event, { name, value }) => {
      this.setState({ [name]: value })
  }

    handleBlur = (event) => {
        this.setState({isEmailValid: validateEmail(this.state.email)})
    }
  
  handLoginInternal = (newPassword, callback, validationData, cleanupOldData) => {
    this.setState({ isSubmitting: true })

    login(this.state.email.toLowerCase() , this.state.password, newPassword, validationData)
      .then(() => {

        if (store.resetPassword) {
          this.setState({ showNewPassword: true, signedIn: false, isSubmitting: false, errorMessage: 'This is first time sign, please type new password' })
        } else {
          this.setState({ signedIn: true, isSubmitting: false, errorMessage: '' })

          const { usagePlanId, token } = this.props

          if (usagePlanId && token) {
            return confirmMarketplaceSubscription(usagePlanId, token)
          }
          
        }

		if (callback) {
			callback()
		}

        // turn this one when domain is ready
        window.location.href="/getting-started"

      })
      .catch((e) => {
		console.log("login causes exception: " + JSON.stringify(e))

		var shouldCallback = true

        var errorMessage = e.message
		if (errorMessage == 'Please provide new password for the first time login.') {
			this.newPasswordRef.current.open(this.handLoginInternal)
		} else if (e.code == 'UserLambdaValidationException' && errorMessage.startsWith('PreAuthentication failed with error VEE: ')) {
			var data = errorMessage.substr('PreAuthentication failed with error VEE: '.length)
			data = data.endsWith(".") ? data.slice(0, -1) : data
			var instruction = data.startsWith("TOP: ") ? data.substr("TOP: ".length) : null
			var errorMsg = data.startsWith("BOTTOM: ") ? data.substr("BOTTOM: ".length) : null
			this.verifyEmailRef.current.open(this.handLoginInternal, instruction, errorMsg, cleanupOldData)
			errorMessage = ""
			shouldCallback = false
        } else if (e.code == 'UnknownError' && newPassword != undefined) {
			errorMessage = ""
		} else if (e.code == 'UserNotFoundException') {
            errorMessage = "Incorrect username or password."
		}
        this.setState({ errorMessage: errorMessage, isSubmitting: false })

		if (callback && shouldCallback) {
			callback()
		}
      })
  }

  handleLogin = (event) => {
	event.preventDefault()
	this.handLoginInternal(undefined, undefined, undefined, true)
  }

  updateUserCredentials = (email, password) => {
	  this.setState({ email: email, password: password })
  }

  render() {
    const { isOpen } = this.state 
    return isAuthenticated()!=null ? <Redirect to='/getting-started' /> : (
		<React.Fragment>
		  <NewPassword ref = {this.newPasswordRef} />
		  <VerifyEmail ref = {this.verifyEmailRef} />

		  <Modal
			size='small'
			open={isOpen}
			onOpen={this.open}
			onClose={this.close}
			trigger={this.props.trigger}
		  >
			<Modal.Header>Login to Helia Developer Portal</Modal.Header>
			<Modal.Content>
			  <Form onSubmit={this.handleLogin} error={!!this.state.errorMessage} loading={this.state.isSubmitting} align="left">
				<Form.Input error={this.state.isEmailValid ? false : "Invalid email address"} fluid icon="at" name='email' autoComplete='false'
							iconPosition="left" placeholder="Email address" value={this.state.email} onChange={this.handleChange} onBlur={this.handleBlur}  />

				<Form.Input fluid icon="lock" name='password' iconPosition="left" placeholder="Password" autoComplete='false' 
									type="password" value={this.state.password} onChange={this.handleChange} />

				{/* <Form.Input type='checkbox' label='First Time Login' name='firstLogin' autoComplete='false' onChange={this.handleChange} /> */}
					{/*
				<Form.Checkbox label='First time login' name='firstLogin' autoComplete='false' onChange={this.handleChange} />

				<Form.Input fluid icon="lock" name='newPassword' iconPosition="left" placeholder="New password" autoComplete='false' 
									type="password" disabled={!this.state.firstLogin} onChange={this.handleChange} />
					*/}
				<Message error content={this.state.errorMessage} />
				<Modal.Actions>
				  <Grid>
					<Grid.Column width={10}>
					  <ResetPassword trigger={<a href="#">Reset password</a>} userCredentialsUpdater={this.updateUserCredentials}/>
					</Grid.Column>
					<Grid.Column width={6} style={{textAlign: 'right'}}>
					  <Button primary type='submit' disabled={!validateEmail(this.state.email)}>Sign in</Button>
					  <Button type='button' onClick={this.close}>Cancel</Button>
					</Grid.Column>
				  </Grid>
				</Modal.Actions>
			  </Form>
			</Modal.Content>
		  </Modal>
		</React.Fragment>
	  )
  }
}
