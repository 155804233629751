// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import ReactDOM from 'react-dom'
import { Grid, Popup, Button, Icon, Form, Message, Modal, Label } from 'semantic-ui-react'
import { Redirect } from 'react-router-dom'
import { login , getApiToken, forgotPassword, confirmPassword, 
		validateEmail, validatePassword, getPasswordRequirement } from 'services/self'
import { confirmMarketplaceSubscription } from 'services/api-catalog'


import { store } from 'services/state'

export default class VerifyEmail extends React.Component {

	state = {
		isSubmitting: false,
		isOpen: false,
		handleLogin: null,
		instruction: "",
		errorMsg: ""
	}

	constructor(props) {
		super(props);
	}

	open = (handleLogin, instruction, errorMsg, cleanUpOldData) => {
		if (cleanUpOldData) {
			this.setState({ code: "" })
		}
		this.setState({ isSubmitting: false, isOpen: true, handleLogin: handleLogin, errorMsg: errorMsg });
		if (instruction != null) {
			this.setState({ instruction: instruction })
		}
	}

	close = () => this.setState({ isOpen: false, isSubmitting: false })

	handleChange = (event, { name, value }) => {
		this.setState({ [name]: value, errorMsg: "" })
	}

	isAllInputDataValid = () => {
		return this.state.code && this.state.code.trim()
	}

    handleSubmit = (event) => {
		event.preventDefault()
		event.stopPropagation()
		this.setState({ isSubmitting: true })
		this.state.handleLogin(undefined, this.close, { verificationCode: this.state.code })
    }

    handleResendCode = (event) => {
		event.preventDefault()
		event.stopPropagation()
		this.state.handleLogin(undefined, undefined, undefined, true)
    }

    render() {

		const { isOpen } = this.state

		return (
			<Modal
				size='small'
				open={isOpen}
				onOpen={this.open}
				onClose={this.close}
				trigger={this.props.trigger}>

				<Modal.Header>Verify email</Modal.Header>
				<Modal.Content>
				  <Form onSubmit={this.handleSubmit} error={!this.isAllInputDataValid()} loading={this.state.isSubmitting}>
					<Grid>
						{ this.state.instruction ?
						<Grid.Row>
							<Grid.Column width={16}>
								<Label color='red' size='large'>{this.state.instruction}</Label>
							</Grid.Column>
						</Grid.Row> : "" }

						<Grid.Row>
							<Grid.Column width={16}>
								<Form.Input fluid icon="paste" name='code' iconPosition="left" autoComplete='false'
											error={this.state.errorMsg ? this.state.errorMsg : false}
											value={this.state.code}
											placeholder={"Enter verification code"} 
											onChange={this.handleChange} />
							</Grid.Column>
						</Grid.Row>
						
						<Grid.Row>
							<Grid.Column width={16} style={{textAlign: 'right'}}>
								<Modal.Actions style={{textAlign: 'right'}}>
								  <Button primary disabled={!this.isAllInputDataValid()} type='submit'>Submit</Button>
								  <Button type='button' onClick={this.handleResendCode}>Resend code</Button>
								</Modal.Actions>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				  </Form>
				</Modal.Content>
		    </Modal>)
    }
}
