// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { reaction } from 'mobx';
import { store } from 'services/state'


/**
 *
 * author: 430008283
 *
 */
export default class AuthorizationAutomator extends React.Component {

    constructor() {
        super()
        this.apiToken = null
        this.intervalId = null
        this.automateApiAuthorization = this.automateApiAuthorization.bind(this)
        // This is to detect if api.token has changed and to trigger authorization.
        this.reactionDisposer = reaction(
            () => store.idToken,
            (newVal, prevVal) => {
                this.automateApiAuthorization();
            }
        );
    }

    componentDidMount() {
        console.log("AuthorizationAutomator is on")
    }

    componentWillUnmount() {
        console.log("AuthorizationAutomator is off")
        this.reactionDisposer();
    }

    automateApiAuthorization = () => {
            // when no API token has been generated, don't authorize
        if (!store.idToken) {
            return
        }

        // get the DOM Authorize button
        // class="btn authorize unlocked" or "btn authorize locked"
        var authButton = document.getElementsByClassName("btn authorize")[0]
        if (!(authButton && authButton.className.includes('locked'))) {
            return
        }

            // the Available authorizations dialog was already opened (by user manually)
        if (authButton.nextSibling && authButton.nextSibling.className == 'dialog-ux') {
            return
        }

        authButton.style = "border-color: #E6E6E6 !important; color: #E6E6E6 !important;"
        authButton.firstElementChild.innerText = "Authorized"
        authButton.firstElementChild.style = "color: #E6E6E6 !important;"

            // get the wrapper React component of the button
        var authorizeBtn = this.findReact(authButton)
            // open the Available Authorization dialog
        authorizeBtn.props.onClick()

/*The following is an example of the Authorize button and the dialog it opens when clicked on

<div class="auth-wrapper">
<button class="btn authorize unlocked">
    <span>Authorize</span>
    <svg width="20" height="20">...</svg>
</button>
<div class="dialog-ux">
    <div class="backdrop-ux"></div>
    <div class="modal-ux" style="display: none">
        <div class="modal-dialog-ux">...</div>
    </div>
</div>
</div>
*/
        var doneButton = null

        var authForms = authButton.nextSibling.getElementsByTagName("form")

        for (var i = 0; i < authForms.length; i++) {
            var af = authForms[i]
            if (af.innerText.startsWith("ApiCognitoAuthorizer")) { // done button, logout button
                if (af.elements.length == 2 && this.apiToken == store.idToken) {
                    // console.log("Click on Logout button ..")
                    af.elements[0].click()
                    // input, done button, authorize button
                }
                if (af.elements.length == 2 && this.apiToken != store.idToken) {
                    // console.log("Click on Logout button ..")
                    af.elements[1].click()
                    // input, done button, authorize button
                } else if (af.elements.length == 3) {
                    var apiTokenInput = this.findReact(af.elements[0]);
                    if (apiTokenInput) {
                        apiTokenInput.props.onChange({ target : { value : store.idToken } })
                            // click on the API key Authorize button
                        af.elements[1].click()
                        this.apiToken = store.idToken
                        console.log("Authorized API token")
                    } else {
                        console.log("ERROR: React component apiTokenInput was null or undefined so couldn't authorize API token")
                    }
                }
            } else if (af.innerText.startsWith("api_key")) { // input, done button, authorize button
                if (af.elements.length == 2 && this.apiKey != store.apiKey) {
                    // console.log("Click on Logout button ..")
                    af.elements[0].click()
                }
                if (af.elements.length == 3) {
                    var apiKeyInput = this.findReact(af.elements[0]);
                    if (apiKeyInput) {
                        apiKeyInput.props.onChange({ target : { value : store.apiKey } })
                            // click on the API key Authorize button
                        af.elements[1].click()
                        console.log("Authorized API key")
                    } else {
                        console.log("ERROR: React component apiKeyInput was null or undefined so couldn't authorize API key")
                    }
                }
            } else  if (af.innerText.startsWith("gnw-customer-id")) { // input, done button, authorize button
                if (af.elements.length == 3) {
                    var apiKeyInput = this.findReact(af.elements[0]);
                    if (apiKeyInput) {
                        apiKeyInput.props.onChange({ target : { value : "258" } })
                        // click on the API key Authorize button
                        af.elements[1].click()
                        console.log("Authorized CID")
                    } else {
                        console.log("ERROR: UNABLE to set CID")
                    }
                }
            }{
                console.log("SHOULD NOT REACH HERE?")
            }
            doneButton = af.elements[af.elements.length - 1]
        }
            // There should be at least one form, and its first element should be the Done button
        doneButton.click()
    }



    findReact = (dom) => {      // from https://stackoverflow.com/a/39165137/4831179
        for (var key in dom) {
            if (key.startsWith("__reactInternalInstance$")) {
                var compInternals = dom[key]._currentElement;
                var compWrapper = compInternals._owner;
                var comp = compWrapper._instance;
                return comp;
            }
        }
        return null;
    };

    render() {
        return null
    }
}

