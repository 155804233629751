import React from 'react'
import { Button, Header, Modal } from 'semantic-ui-react'



class AlertBox extends React.Component {
	state = { 
		isOpen: false,
		header: '',
		messages: '',
		positive: false,
		onClose: null
	};

	open = (header, messages, positive=false, onClose=null) => {
		this.setState({ 
			isOpen: true,
			header: header,
			messages: messages,
			positive: positive,
			onClose: onClose})
	}

	close = () => {
		this.setState({ isOpen: false })
		if (this.state.onClose) {
			this.state.onClose()
		}
	}

	render() {
		return (
		  <Modal 
		    dimmer={true}
			size='tiny'
			open={this.state.isOpen}
			onOpen={this.open}
			onClose={this.close}
			centered={false}
		  >
			<Modal.Content>
			  <Modal.Description>
			    {this.state.header && this.state.header.trim() && 
				    <React.Fragment>
					    <Header as='h3' color={this.state.positive ? 'green' : 'red'} dividing={true}>{this.state.header.trim()}</Header>
				    </React.Fragment>
				}


				{ this.state.messages.split("\n").map(function(line, index) { 
				     return (<p key={'k' + index}>{line}</p>)
				  })
				}

				</Modal.Description>
			  
			  <Modal.Actions style={{textAlign: 'right'}}>
			    {this.state.positive ? (<Button onClick={this.close} positive>OK</Button>) : 
					(<Button onClick={this.close} negative>OK</Button>)}
			  </Modal.Actions>
			</Modal.Content>
		  </Modal>
		)
	}
}


export default AlertBox

