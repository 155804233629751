// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'
import { store } from 'services/state.js'
import { isAuthenticated,isLiveSession } from 'services/self'

// semantic-ui
import { Header, Segment, Container, Button } from 'semantic-ui-react'

import { downloadFile } from 'services/self'
export default observer(class DocPage extends React.Component {
  render() {
    return !isAuthenticated() ? <Redirect to='/' /> : (
      <Container style={{ padding: "40px" }}>
        <Segment vertical textAlign='left' >

          <fragments.B2BGateway.jsx />
          {/* <Button onClick={() => downloadFile("sample.pdf")} >Documents</Button> */}
        </Segment>
      </Container>
    )
  }
})
