// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react'

// mobx
import { observer } from 'mobx-react'

// fragments
import { fragments } from 'services/get-fragments'

// react-router
import { Link } from 'react-router-dom'

// semantic-ui
import { Header, Segment, Container, Image, Button } from 'semantic-ui-react'

let imgUrl = '/custom-content/center-background-image.jpg'

export const HomePage = observer(() => (
  <React.Fragment>
    <Segment vertical textAlign='center' style={{ color: "whitesmoke", backgroundColor: "slategray", padding: "40px 0px",
     backgroundImage: `url(${imgUrl})`, margin: "0px !important", height:"280px",backgroundSize: 'cover' }}>
      <div style={{height:"60px"}}></div>
      <Header as='h1' style={{ color: "black" }}>{fragments.Home.header}</Header>
      <p  style={{ color: "black" }}>{fragments.Home.tagline}</p>
      {/*
      <Link to="/getting-started"><Button positive>{fragments.Home.gettingStartedButton}</Button></Link>
      <Link to="/apis" style={{ padding: "0.78571429em 1.5em 0.78571429em", color: "white" }}>{fragments.Home.apiListButton}</Link>
      */}   
    </Segment>
    <Segment vertical style={{ padding: "40px 0px", margin: "0 !important" }}>
      <Container fluid text textAlign='justified'>
        <fragments.Home.jsx />
      </Container>
    </Segment>
  </React.Fragment>
))

export default HomePage
