// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// content-fragments (import here to start this ASAP)
import 'services/get-fragments'

// fonts
import './fonts/Sohne-Buch.otf';
import './fonts/Sohne-Kraftig.otf';
import './fonts/Sohne-Leicht.otf';

// semantic-ui
import 'semantic-ui-css/semantic.css'

// pages
import Home from 'pages/Home'
import GettingStarted from 'pages/GettingStarted'
import Dashboard from 'pages/Dashboard'
import Apis from 'pages/Apis'
import B2BGateway from "pages/B2BGateway";
import TermsAndConditions from "pages/TermsAndConditions"

// components
import AlertPopup from 'components/AlertPopup'
import NavBar from 'components/NavBar'

import { init, logout ,isLiveSession} from 'services/self'
import './index.css';

class App extends React.Component {
  constructor() {
    super()
    init()

    // We are using an S3 redirect rule to prefix the url path with #!
    // This then converts it back to a URL path for React routing
    const hashRoute = window.location.hash.substring(2)
    window.history.pushState({}, 'home page', hashRoute)
  }

  componentDidMount() {
    this.interval = setInterval(() => {
     if(!isLiveSession()){
        logout()
      }
    }, 2000);
  }
  
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <BrowserRouter>
        <React.Fragment>
          <NavBar />
          <AlertPopup />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/getting-started" component={GettingStarted} />
            <Route path="/dashboard" component={Dashboard} />
            <Route exact path="/apis" component={Apis} />
            <Route path="/apis/:apiId" component={Apis} />
            <Route path="/b2b-gateway" component={B2BGateway} />
            <Route path="/termsandconditions" component={TermsAndConditions} />
            <Route component={() => <h2>Page not found</h2>} />
          </Switch>
        </React.Fragment>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(
  <App />,
  document.getElementById('root')
);


if (module.hot) {
	module.hot.accept()
}
