// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import AWS from 'aws-sdk'
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails
} from 'amazon-cognito-identity-js'

// services
import {
  store
} from 'services/state'
import {
  initApiGatewayClient,
  apiGatewayClient
} from 'services/api'
import {
  updateAllUserData
} from 'services/api-catalog'
import {
  cognitoIdentityPoolId,
  cognitoUserPoolId,
  cognitoClientId,
  cognitoRegion
} from 'services/api'

const poolData = {
  UserPoolId: cognitoUserPoolId,
  ClientId: cognitoClientId
}

let userPool

var terms

export function isAuthenticated() {
  // AWS.config.credentials.expired
  if (isLiveSession())
    return store.cognitoUser 
  else
    return null;
}


export function isLiveSession() {
  var current_time = new Date().getTime() / 1000;
  
  if (AWS.config.credentials && AWS.config.credentials.expireTime){
    var exp = AWS.config.credentials.expireTime.getTime()/1000;
    // console.log("livesession:"+(current_time-exp))
    if (current_time>exp){
       return false;
    }
 }
 return true;
}
function getCognitoLoginKey() {
  return `cognito-idp.${cognitoRegion}.amazonaws.com/${cognitoUserPoolId}`
}

export function init() {
  // attempt to refresh credentials from active session
  userPool = new CognitoUserPool(poolData)
  store.cognitoUser = userPool.getCurrentUser()

  if (store.cognitoUser !== null) {
    store.cognitoUser.getSession(function (err, session) {
      if (err) {
        logout()
        console.error(err)
        return
      }

      const cognitoLoginKey = getCognitoLoginKey()
      const Logins = {}
      Logins[cognitoLoginKey] = session.getIdToken().getJwtToken()
      terms = [session.getIdToken()['payload']['custom:Terms'], session.getAccessToken().getJwtToken()]
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognitoIdentityPoolId,
        Logins: Logins
      })

      AWS.config.credentials.refresh((error) => {
        if (error) {
          logout()
          console.error(error)
        } else {
          initApiGatewayClient(AWS.config.credentials)
          updateAllUserData()
        }
      })
    })
  } else {
    initApiGatewayClient()
  }
}

function refreshSession() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          reject(err);
        } else {
          cognitoUser.refreshSession(session.getRefreshToken(), (err, newSession) => {
            if (err) {
              reject(err);
            } else {
              resolve(newSession);
            }
          });
        }
      });
    } else {
      reject(new Error('No current user'));
    }
  });
}


export function hasAccepted() {
  return terms
}

export async function updateCustomAttribute(token) {
  const cognito = new AWS.CognitoIdentityServiceProvider();
  const params = {
    AccessToken: token,
    UserAttributes: [
      {
        Name: 'custom:Terms',
        Value: "1"
      }
    ]
  };
  try {
    await cognito.updateUserAttributes(params).promise();
    await refreshSession();
    console.log(`Updated attribute`);
  } catch (error) {
    console.error('Error updating user attribute:', error);
  }
}


export function register(email, password) {
  localStorage.clear()
  const attributeList = []
  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        reject(err)
      } else {
        resolve(login(email, password))
      }
    })
  })
}

export function login(email, password, newPassword, validationData) {
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
    ValidationData: validationData
  })

  let localCognitoUser = new CognitoUser({
    Username: email,
    Pool: new CognitoUserPool(poolData)
  })

  return new Promise((resolve, reject) => {
    localCognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {

        store.cognitoUser = localCognitoUser

        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: cognitoIdentityPoolId,
          Logins: {
            [getCognitoLoginKey()]: result.getIdToken().getJwtToken()
          }
        })
        AWS.config.credentials.refresh((error) => {
          if (error) {
            console.error(error)
          } else {
            initApiGatewayClient(AWS.config.credentials)

            updateAllUserData()
            apiGatewayClient().then(apiGatewayClient =>
              apiGatewayClient.post('/signin', {}, {}, {})
              .then(resolve)
              .catch(reject)
            )
          }
        })
      },

      onFailure: reject,
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new 
        // password and required attributes, if any, to complete 
        // authentication.

        // userAttributes: object, which is the user's current profile. It will list all attributes that are associated with the user. 
        // Required attributes according to schema, which don’t have any values yet, will have blank values.
        // requiredAttributes: list of attributes that must be set by the user along with new password to complete the sign-in.
        if (!newPassword) {
          store.resetPassword = true;
          reject({
            message: "Please provide new password for the first time login."
          })
        } else {
          localCognitoUser.completeNewPasswordChallenge(newPassword, {}, this).then(() => {
            const authenticationDetail = new AuthenticationDetails({
              Username: email,
              Password: newPassword
            })
            localCognitoUser.authenticateUser(authenticationDetail, {
              onSuccess: (result) => {

                store.cognitoUser = localCognitoUser

                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                  IdentityPoolId: cognitoIdentityPoolId,
                  Logins: {
                    [getCognitoLoginKey()]: result.getIdToken().getJwtToken()
                  }
                })

                AWS.config.credentials.refresh((error) => {
                  if (error) {
                    console.error(error)
                  } else {
                    initApiGatewayClient(AWS.config.credentials)

                    updateAllUserData()

                    apiGatewayClient().then(apiGatewayClient =>
                      apiGatewayClient.post('/signin', {}, {}, {})
                      .then(resolve)
                      .catch(reject)
                    )
                  }
                })
              }
            })
            resolve()
          })
        }
      }
    })
  })
}

export function logout() {
  if (store.cognitoUser) {
    store.cognitoUser.signOut()
    store.resetUserData()
    localStorage.clear()
    window.location.href="/"
  }
}
function copyToken(text){

  document.getElementById("apitokenInput").value=text;

  var copyText = document.getElementById("apitokenInput");

  /* Select the text field */
  copyText.select();

}

export function getApiToken(username, password) {
  var poolData = {
    UserPoolId: window.config.apiUserPoolId, // Your user pool id here
    ClientId: window.config.apiUserPoolClientId // Your client id here
  };

  var userPool = new CognitoUserPool(poolData);

  var userData = {
    Username: username, // your username here
    Pool: userPool
  };

  var authenticationData = {
    Username: username,  // your username here
    Password: password, //
  };
  var authenticationDetails = new AuthenticationDetails(authenticationData);

  var cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        console.log(result)
      
        store.username = username
        store.password = password
        store.idToken = result.getIdToken().getJwtToken()
        store.tokenStartTime = new Date()

        copyToken(store.idToken);
      },

      onFailure: reject,
      mfaRequired: function (codeDeliveryDetails) {
        var verificationCode = prompt('Please input verification code', '');
        cognitoUser.sendMFACode(verificationCode, this);
      }
    });
  })
}

export function downloadFile(fileKey) {
    
  var s3 = new AWS.S3();

  const myBucket = window.config.artifactBucket;
  const myKey = "Documents/" + fileKey;
  const signedUrlExpireSeconds = 300;

  const url = s3.getSignedUrl('getObject', {
    Bucket: myBucket,
    Key: myKey,
    Expires: signedUrlExpireSeconds
  });
  if (url==="https://s3.ap-southeast-2.amazonaws.com/"){
    logout();
  }else{
    window.open(url, "_blank", "_self")
  }
}



export function downloadSchema(){
  var params = {
    exportType: 'swagger', /* required */
    restApiId: 'v84prkwkq5', /* required */
    stageName: 'v1', /* required */
    accepts: 'application/json',
  };

  var apigateway = new AWS.APIGateway();
 
    apigateway.getExport(params, function(err, data) {
      if (err) {
        console.log(err, err.stack);
      }
      else {
        console.log(data.body);      
      }
    })
}


export function forgotPassword(username, callbacks=null) {
	var poolData = {
		UserPoolId: window.config.userPoolId, // Your dev portal user pool id here
		ClientId: window.config.userPoolClientId // Your dev portal client id here
	};

	var userPool = new CognitoUserPool(poolData);

	var userData = {
		Username: username, // your username here
		Pool: userPool
	};

	var cognitoUser = new CognitoUser(userData);
	cognitoUser.forgotPassword({
        onSuccess: (result) => {
			if (callbacks && callbacks.onSuccess) {
				callbacks.onSuccess(result)
			}
        },
        onFailure: (err) => {
			if (callbacks && callbacks.onFailure) {
				callbacks.onFailure(err);
			}
        }
    });
}

export function confirmPassword(username, newPassword, verificationCode, callbacks=null) {

	var poolData = {
		UserPoolId: window.config.userPoolId, // Your dev portal user pool id here
		ClientId: window.config.userPoolClientId // Your dev portal client id here
	};

	var userPool = new CognitoUserPool(poolData);

	var userData = {
		Username: username, // your username here
		Pool: userPool
	};

	var cognitoUser = new CognitoUser(userData);
	cognitoUser.confirmPassword(verificationCode, newPassword, {
		onSuccess: () => {
			if (callbacks && callbacks.onSuccess) {
				callbacks.onSuccess()
			}
		},
		onFailure: (err) => {
			if (callbacks && callbacks.onFailure) {
				callbacks.onFailure(err)
			}
		}
	});
}


export function	validateEmail(email) {
	var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
}

export function validatePassword(password) {
	var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~`!@#\$%\^&\*=\,\.\?/':;\"])(?=.{8,})");
	return strongRegex.test(password)
}

export function getPasswordRequirement() {
	return "Passwords must be at least 8 characters in length and contain at least one numeral, " + 
			"one uppercase letter, one lowercase letter and one special character from ~`!@#$%^&*=,.?/':;\""
}

