import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Modal, Button } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';
import { store } from 'services/state.js';
import { isAuthenticated, logout, hasAccepted, updateCustomAttribute} from 'services/self';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const TermsAndConditionsPopup = () => {
  const stuff = hasAccepted()
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [canAccept, setCanAccept] = useState(false);
  const scrollRef = useRef(null);
  const SCROLL_THRESHOLD = 0.9;

  useEffect(() => {
    setOpen(stuff[0] == 0 ? true : false);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
  }

  const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        const scrollPercentage = (scrollTop + clientHeight) / scrollHeight;
            if (scrollPercentage >= SCROLL_THRESHOLD) {
              setCanAccept(true);
            }
      }
  };

  const handleAccept = () => {
    updateCustomAttribute(stuff[1]);
    setOpen(false);
  };

  const handleDecline = () => {
    logout();
    return <Redirect to="/" />;
  };

  return (
    <Modal open={open} closeIcon={false}>
      <Modal.Header>Terms and Conditions</Modal.Header>
      <Modal.Content>
        <div className="modal">
                <div
                className="modal-body"
                style={{ height: '60vh', overflowY: 'auto' }}
                ref={scrollRef}
                onScroll={handleScroll}
                >
                  <Document
                    file="/TermsAndConditions.pdf"
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      scale={1.4}
                      />
                    ))}
                  </Document>
                </div>
              </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleAccept} positive disabled={!canAccept}>
          Accept
        </Button>
        <Button onClick={handleDecline} negative>
          Decline
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default TermsAndConditionsPopup;